/* @import url('https://fonts.googleapis.com/css2?family=VT323&display=swap'); */

body {
  background: #060606;
  text-align: center;
  height: 100%;
}

.background {
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  z-index: -2;
}
.background-shade {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  z-index: -1;

}

h1 {
  text-align: center;
  font-family: Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;  color: #181818;
  font-size: 4rem;
  margin: 1rem;
}

p {
  font-size: 1.25rem;
  line-height: 1.5rem;
}

.avatar {
  margin: 1rem 0;
  margin-top: 4rem;
  border-radius: 50%;
  width: 300px;
  box-shadow: 0px 3px 6px rgba(0,0,0, 0.3);

}

@media (prefers-color-scheme: dark) {
  .background-shade {
    background: linear-gradient(#060606, 85%, rgba(0,0,0, 0.7));
  }
  h1 {
    color: #fff;
  }
  p {
    color: rgba(255, 255, 255, 0.7);
  }
}

@media (prefers-color-scheme: light) {
  .background-shade {
    background: rgba(255, 255, 255, 0.7);
  }
  h1 {
    color: #181818;
  }
  p {
    color: rgba(#181818, 0.7);
  }
}
